<template>
<div class="d-flex">
  <b-input-group>
    <div class="form-group form-group-feedback form-group-feedback-left mb-0">
      <input type="text" class="form-control" v-model="propValue" v-on:keyup.13="$emit('search')" :placeholder="placeholder">
      <div class="form-control-feedback">
        <i class="icon-search4"></i>
      </div>
    </div>
  </b-input-group>
  
  <b-button
    class="ml-1 d-inline-flex align-items-center"
    variant="outline-success"
    id="resetBtn"
    @click="$parent.doReset()"
  >Reset</b-button>
</div>
</template>

<script>
export default{
  props:{
    value:[String, Number],
    placeholder:{ default: "Ketik dan tekan enter ...", type:String },
  },
  data(){
    return {
      propValue:""
    }
  },
  mounted(){
    this.$set(this,'propValue', this.value)
  },
  watch:{
    value(){
      this.$set(this,'propValue', this.value)
    },
    propValue(){
      this.$emit('update:value', this.propValue)
    }
  }
}
</script>